<template>
  <div class="content text-center">
    <div class="content__title">Transcript</div>
    <div class="content__description">Smart document reader</div>
    <form @submit="onSubmit" class="content__form d-flex flex-column align-items-center mt-5">
      <b-form-input v-model="form.email" type="text" required class="content__form__input shadow-none my-2 p-2"
        autocomplete="off" placeholder="Email"></b-form-input>
      <b-form-input v-model="form.password" type="password" required class="content__form__input shadow-none my-2 p-2"
        autocomplete="off" placeholder="Password"></b-form-input>
      <div class="mt-1 text-danger text-center" v-if="invalidAuth">
        Invalid email or password
      </div>
      <b-button class="content__form__submit mt-5" variant="secondary" type="submit" :disabled="authenticating">
        <b-spinner v-if="authenticating" variant="default" type="grow" label="Spinning"></b-spinner>
        <span v-else>Login</span>
      </b-button>
      <!-- <div class="content__form__register my-2">
        I don’t have an account. <span class="text-success"> Register </span>
      </div> -->
    </form>
  </div>
</template>
<script>
  import LoginService from "../services/LoginService";
  import {
    initializeFirebase
  } from "@/services/PushNotificationsService";

  export default {
    data() {
      return {
        form: {
          email: "",
          password: "",
        },
        authenticating: false,
        invalidAuth: false,
      };
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault();
        this.invalidAuth = false;
        this.authenticating = true;

        LoginService.authenticate(this.form.email, this.form.password)
          .then(
            (res) => {
              const token = res.data;
              LoginService.persistTokenInSessionStorage(token);
              this.$router.push("/documents").then(() => {
                // let's save the device token for futher push notification
                initializeFirebase();
              });
            },
            (err) => {
              this.invalidAuth = true;
              console.error("Error:", err);
            }
          )
          .finally(() => {
            this.authenticating = false;
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../styles/scss/variables";

  .content {
    padding-top: 10.2rem;

    .content__title {
      color: black;
      font-weight: bolder;
      font-size: 3rem;
    }

    .content__description {
      color: $primary;
      font-weight: lighter;
       font-size: 1.7rem;
    }

    .content__form {
      padding-top: 1.2rem;
      .content__form__input {
        background-color: $input-background;
        color: $secondary;
        border-top: none;
        width: 22rem;
        height: 3.5rem;
        font-size: 1.5rem;
      }

      .content__form__submit {
        width: 22rem;
        background-color: $login-button;
         height: 3.5rem;
           font-size: 1.5rem;
      }

      .content__form__register {
        font-size: 1.2rem;
      }
    }
  }
</style>