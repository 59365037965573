import firebase from 'firebase/app';
import 'firebase/messaging';
import http from "../http";


const SAVE_TOKEN = "fcm/add"
// This function registers the device token for future notifications
const registerTokenForPushNotification = (fcmToken: string) => http.put(SAVE_TOKEN, fcmToken);

const firebaseUid = '';

export function initializeFirebase() {
    if (firebase.messaging.isSupported()) {
        const firebaseConfig = {
            authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
            databaseURL: process.env.VUE_APP_FCM_DATABASE_URL,
            projectId: process.env.VUE_APP_FCM_PROJECT_ID,
            storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
            messagingSenderId: process.env.VUE_APP_FCM_SENDER_ID,
            apiKey: process.env.VUE_APP_FCM_API_KEY, 
            appId: process.env.VUE_APP_FCM_APP_ID,
            measurementId: process.env.VUE_APP_FCM_MEASUREMENT_ID
        };
        firebase.initializeApp(firebaseConfig);
        const messaging = firebase.messaging();

        navigator.permissions.query({
            name: 'notifications'
        }).then(function (permissionStatus) {
            console.log('notifications permission state is ', permissionStatus.state);
            if(permissionStatus.state === "prompt" || permissionStatus.state === "denied" ){
                    messaging.getToken().then(FCM_TOKEN => {
                        console.log('2. Received FCM Token', FCM_TOKEN);
                        registerTokenForPushNotification(FCM_TOKEN).then(() => {
                            console.log('3. Registered FCM Token');
                        })
                    }).catch((error) => {
                        console.error(error);
                    });
                }
                console.log('Permission was granted for notifications');
        })
        messaging.onMessage(function (payload) {
            const sender = JSON.parse(payload.data.message);
            console.log('Receiving foreground message', JSON.parse(payload.data.message));
            // Customize notification here
            if (sender.data.entities.sender.entity.uid !== firebaseUid) {
                const notificationTitle = 'New Invoice Available';
                const notificationOptions = {
                    body: payload.data.alert,
                    icon: sender.data.entities.sender.entity.avatar,
                };
                const notification = new Notification(notificationTitle, notificationOptions);
                notification.onclick = function (event) {
                    notification.close();
                    console.log(event);
                };
            }
        });
    }
}
